*{
    margin: 0;
    padding: 0;
}
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,700&family=Poppins:wght@300&display=swap);

.title-yellow-contactUs{
    color: #F2B705;
    font-size: 3rem;
    font-family: 'Montserrat', sans-serif;
    margin-left: 9rem;
    margin-top: 8rem;
    font-weight: 700;
    
}

.subtitle-contactUs{
    font-family: 'Poppins', sans-serif;
    font-size: 2rem;
    margin-left: 9rem;
    
}

.align-row-contactUs{
    display: flex;
    justify-content: space-around;
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
}
.align-column-contactUs{
    display: flex;
    flex-direction: column;
    width: 39rem;
    
}

.container-purple-contactUs{
width: 100%;
height: 18rem;
background-color: #AD4AFF;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin-bottom: 5rem;
}

.container-purple-contactUs img{
    margin-top: 4rem;
    margin-left: 1.5rem;
    width: 2.5rem;
    height: 2.5rem;
}
.container-purple-contactUs p{
    color: white;
    font-size: 2rem;
}

.align-btn-contactUs{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}


.textField-contactUs{
    height: 10rem; 
    width: 100%;
    border-radius: 1rem;
    border: solid 2px #F2B705;
    box-shadow: 2px 2px 4px 2px #F2B705;
    margin-top: 1rem;
    resize: none;
    color: black;
}

.submit-contactUs{
    width: 10rem;
    height: 3rem;
    background-color: #F2B705;
    color: white;
    border: none;
    font-size: 1.3rem;
    font-weight: 600;
    border-radius: 1.7rem;
    font-family: 'Poppins', sans-serif;
    box-shadow: inset 3px 3px 4px 0px rgba(0,0,0,0.5);

    
}

.img-principal-contactUs{
    width: 23rem;
    height: 30rem;
}
.inputContactUs{
    width: 100%;
    padding: 1rem;
    border-radius: 1rem;
    border: solid 2px #F2B705;
    box-shadow: 2px 2px 4px 2px #F2B705;
}

.inputContactUs:focus {
    outline: none;
}
.inputSmallContactUs{
    width: 50%;
    padding: 1rem;
    border-radius: 1rem;
    border: solid 2px #F2B705;
    box-shadow: 2px 2px 4px 2px #F2B705;
}

.inputSmallContactUs:focus {
    outline: none;
}

@media (max-width: 1200px) {
    .title-yellow-contactUs{
        color: #F2B705;
        font-size: 3rem;
        font-family: 'Montserrat', sans-serif;
        margin-left: 3.8rem;
        margin-top: 8rem;
        font-weight: 700;
        
    }
    
    .subtitle-contactUs{
        font-family: 'Poppins', sans-serif;
        font-size: 2rem;
        margin-left: 3.8rem;
        
    }
    
}



@media(max-width:1000px)  {
    
   

    .title-yellow-contactUs{
        color: #F2B705;
        font-size: 2rem;
        font-family: 'Montserrat', sans-serif;
        margin-left: 3.8rem;
        
    }
    
    .subtitle-contactUs{
        font-family: 'Poppins', sans-serif;
        font-size: 1.3rem;
        margin-left: 0rem;
        margin-left: 3.8rem;
    }


    .container-purple-contactUs{
        width: 100%;
        height: 13rem;
        background-color: #AD4AFF;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        }
        
        .container-purple-contactUs img{
            margin-top: 2rem;
            margin-left: 1rem;
            width: 2.4rem;
            height: 2.4rem;
        }
        .container-purple-contactUs p{
            color: white;
            font-size: 1.5rem;
        }

        .textField-contactUs{
            height: 7rem;
            width: 100%; 
            border-radius: 1rem;
            border: solid 2px #F2B705;
            box-shadow: 2px 2px 4px 2px #F2B705;
            margin-top: 1rem;
            resize: none;
        } 
        .align-column-contactUs{
            display: flex;
            flex-direction: column;
            width: 33rem;
            
        }
        
    }

    @media(max-width:600px)  {
    

        .img-principal-contactUs{
            display: none;
         }

        .align-column-contactUs{
            display: flex;
            flex-direction: column;
            width: 23rem;
            
        }


        .inputContactUs{
            width: 100%;
            padding: 1rem;
            border-radius: 1rem;
            border: solid 2px #F2B705;
            box-shadow: 2px 2px 4px 2px #F2B705;
        }
        .inputSmallContactUs{
            width: 50%;
            padding: 1rem;
            border-radius: 1rem;
            border: solid 2px #F2B705;
            box-shadow: 2px 2px 4px 2px #F2B705;
        }
    
    }
    
    @media(max-width:600px)  {
        .container-purple-contactUs p{
            font-size: 1.3rem ;
        }
    
    }