.input-form{
    background-color: #AD4AFF;
    color: white;
    border: none;
    width: 20rem;
    height: 3rem;
    border-radius: 1rem;
    margin-top: 2rem;
    font-size: 1.2rem;
    font-family: 'Poppins', sans-serif;
    
}

.input-form:focus {
    outline: none;
}

@media (max-width: 600px) {
.input-form{
    background-color: #AD4AFF;
    color: white;
    border: none;
    width: 20rem;
    height: 3rem;
    border-radius: 1rem;
    margin-top: 2rem;
    font-size: 1.2rem;
    
}}