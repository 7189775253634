
.button-universal button {
    background-color: #C9A5FF;
    color: #f2f2f2;
    padding: 14px 16px;
    border: none;
    border-radius: 10px;
    font-family: Poppins;
    font-size: 0.8rem;
    font-weight: 700;
    cursor: pointer;
}
.button-universal button:hover {
    background: #AD4AFF;
    color: #000;
    transition: 1.3s;
}
@media (max-width: 530px){
    .button-universal button {
    padding: 8px 8px;
    border: none;
    border-radius: 7px;
    font-family: Poppins;
    font-size: 0.8rem;
    font-weight: 700;
    cursor: pointer;
    }
}

