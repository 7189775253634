.container-tutorial-one {
    background: url(../../../assets/bg-2-game-1.jpg) center no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
}
@media screen and (max-width:485px) {
    .container-tutorial-one {
        height: 115vh;
    }
}
@media screen and (max-width:450px) {
    .container-tutorial-one {
        height: 125vh;
    }
}