* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  @import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,700&family=Poppins:wght@300&display=swap);

  
  .p-general{
    font-size: 1.4rem;
    width: 90%;
    text-align: center;
    margin-left: 1rem;
    font-family: 'Poppins', sans-serif;
  }


  

.btnAmarelo{
    padding: 1.3rem;
    width: 15rem;
    font-weight: 800;
    background-color: #F2B705;
    color: white;
    border: none;
    font-size: 1.2rem;
    font-family: 'Montserrat', sans-serif;
    border-radius: 1rem; 
    display: flex;
    justify-content: center;
    cursor: pointer;

  }

  .alinharBtn{
    display: flex;
    justify-content: right;
    margin-right: 7rem;
    margin-bottom: 3rem;

  }

  .img-calculate-shipping{
    height: 25rem;
  }


  .inputsGroup{
    display: flex;
    flex-direction: row;
    justify-content: center;
    
  } 
 
  .inputsGroupH4TelaFinal{
    margin-left: 20rem;
    text-align: center;
    font-size: 3rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    margin-top: 0.5rem;
  }

  .inputsGroupPTelaFinal{
    margin-top: 2rem;
    margin-left: 20rem;
    text-align: center;
    font-size: 1.4rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
  }

  .inputsGroup .flex-row-small{
    margin-top: 3rem;
    
  }
  .imgBackTelaFinal{
    width: 3rem;
    height: 3rem;
    margin: 1rem;
    position: relative;
  }
  
  .align-flex{
    display: flex;
    flex-direction: column;
  }
  .flex-row-small{
    display: flex;
    flex-direction: row;
   
  }
  
  .flex-row-big{
    margin-top: 3rem;
  }

  .flex-row-small input{
    margin-left: 1rem;
  }
  .flex-row-big input{
    margin-left: 1rem;
  }
  .inputTelaFinal{
    width: 37rem;
    padding: 1rem;
    border-radius: 1rem;
    border: solid 2px #ae4aff96;
    box-shadow: 2px 2px 4px 2px #ae4aff70;
}

.inputTelaFinal:focus {
    outline: none;
}
.inputTelaFinaSmall{
  width: 18rem;
  padding: 1rem;
  border-radius: 1rem;
  border: solid 2px #ae4aff96;
  box-shadow: 2px 2px 4px 2px #ae4aff70;
}

.inputTelaFinaSmall:focus {
  outline: none;
}


  @media (max-width: 1000px) {


  
  .tituloImg{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 0rem;
  }
   .tituloImgP{
    font-size: 1.9rem;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    margin-right: 2rem;
    width: 100%;
  }

   .h4-general{
      font-size: 2.7rem;
      text-align: center;
      margin-bottom: 2rem;
      margin-top: 2rem;
       font-family: 'Montserrat', sans-serif;
      
  }


.btnAmarelo{
    padding: 1rem;
    width: 13rem;
    background-color: #F2B705;
    color: white;
    border: none;
    font-size: 1.2rem;
    font-family: 'Montserrat', sans-serif;
    border-radius: 1rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    display: flex;
    justify-content: center;
    cursor: pointer;

  }

  .alinharBtn{
    display: flex;
    justify-content: right;
    margin-right: 1rem;

  }

 
  .inputsGroup{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0rem;
    margin-left: 0rem;
    
  }
  .inputsGroupH4TelaFinal{
    margin-left: 0rem;
    text-align: center;
    font-size: 3rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
  }

  .inputsGroupPTelaFinal{
    margin-left: 4.5rem;
    margin-top: 2rem;
    text-align: center;
    font-size: 1rem;
    width: 70%;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
  }


  .inputsGroup img{
    display: none;
  }

}

@media(max-width:600px)  {
    

.inputTelaFinaSmall{
  width: 11rem;
  padding: 1rem;
  border-radius: 1rem;
  border: solid 2px #ae4aff96;
  box-shadow: 2px 2px 4px 2px #ae4aff70;
}


  .inputTelaFinal{
      width: 23rem;
      padding: 1rem;
      border-radius: 1rem;
      border: solid 2px #ae4aff96;
      box-shadow: 2px 2px 4px 2px #ae4aff70;
  }}