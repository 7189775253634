@import url(https://fonts.googleapis.com/css2?family=Baloo+2:wght@600;700;800&family=Montserrat:wght@400;600;800;900&family=Poppins:wght@100;300;400;500;600;700;800;900&display=swap);

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video, input {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
p, button, input, label {
	font-family: 'Poppins', sans-serif;
}
h1 {
	font-family: 'Montserrat', sans-serif;
}
h2,h3,h4,h5,h6{
	font-family: 'Baloo 2', sans-serif;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

::-webkit-scrollbar-track {
    background-color: #d5d3d7;
}
::-webkit-scrollbar {
    width: 0.6rem;
    background: #b5b5b5;
}
::-webkit-scrollbar-thumb {
    background: #C9A5FF;
}

.router{
	text-decoration: none;
	text-transform: none;
}
.link-router{
	text-decoration: none;
}
.footer{
	background-color: #f5f5f5;
}
