
.container-nopage{
    display: flex;
    justify-content: center;
    margin: auto;

}

.img-nopage img{
    width: 80%;
    margin: 2rem;

}

.info-nopage{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.subt-nopage{
    font-size: 6rem;
}

.p-nopage{
    padding-bottom: 2rem;
}

@media (max-width:730px){
    .img-nopage{
        display: none;

    }

    .info-nopage{
        margin: 3rem;
        margin-top: 10rem;
    }
}


