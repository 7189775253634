.c-register-parents{
    width: 100vw;
    height: 100vh ;
}



.containerSection{
    display: flex;
    justify-content:space-between;
    flex-direction: row;
}

::-webkit-scrollbar{
    display: none;
}

.subtitle-register{
    font-size: 1.7rem;
    font-weight: 800;
}

.forms-left{
    display: flex;
    flex-direction: column;
    justify-content:start;
    width: 80vh;
    margin: 2rem 3rem;
    padding: 0.2em;
}

.forms-register{
    width: 70vh;
    padding: 0.2em;

}

.btn-register{
    margin-top: 0.6em;
    display: flex;
    gap: 0.7em;
    align-items: center;
}

.image-rigthw{
    height: 100vh;
}

.paragrafS{
    font-size: 0.8em;
}

.link{
    font-size: 0.9rem;
    font-weight: 600;
    color: #C9A5FF;
}

.link:hover{
    color: #AD4AFF;
}

.link-router{
    text-decoration: none;
}

.buttonRegister {
    margin-top: 15px;
}


@media (max-width:1090px) {
    .img-rigth{
        display: none;
    }
    
}

@media (max-width:660px) {

    .info-register{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .forms-left{
        width: 22rem;
        margin: 0rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }


    .forms-register{
        width: 22rem;
        
    }

    .btn-register{
        justify-content: space-between;
    
    }
    .containerSection{
        display: flex;
        justify-content:center;
    }


}

@media (max-width:480px) {

    .info-register{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;    
        margin: auto;
    }

    .forms-left{
        width: 22rem;
        margin: 0rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

    }

    .register-form-p{
        margin: 1.3rem;
    }

    .forms-register{
        width: 100%;
    }

    .btn-register{
        justify-content: center;
    
    }

    .btn p, span{
        font-size: 0.7em;
    }
}