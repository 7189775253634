.container-hair-types {
    background: url(../../../assets/bg-hair-types.jpg) center no-repeat;
    background-size: cover;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.back-games{
    top: 1.8rem;
    left: 2rem;
    position: fixed;
}
.container-hair-types h2 {
    font-size: 1.6rem;
    color: #fff;
    font-weight: 600;
}
.container-hair-types img {
    width: 650px;
    margin: 1.5rem 0px;
}
.container-hair-types button {
    background-color: #C9A5FF;
    border: none;
    border: 1px solid #AD4AFF;
    color: #1E1E1E;
    font-weight: bold;
    padding: 10px 70px;
    border-radius: 10px;
}

@media screen and (max-width: 964px) {
    .container-hair-types {
        height: 108vh;
    }
    .container-hair-types h2 {
        text-align: center;
        margin-top: 5rem;
    }
}
@media screen and (max-width:751px) {
    .back-games{
        top: 1rem;
        left: 1rem;
    }
    .container-hair-types h2 {
        margin-top: 3rem;
    }
    .container-hair-types img {
        width: 70%;
    }
}
@media screen and (max-width:460px) {
    .container-hair-types h2 {
        font-size: 1.2rem;
        margin-top: 3rem;
    }
    .container-hair-types img {
        width: 80%;
    }
}