.paymentFormHeader {
    background-color: #0ABBB5;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px;
}

.paymentFormHeader h2 {
    font-weight: 700;
    font-size: 1.3rem;
    font-family: 'Baloo 2', sans-serif;
    align-items: center;
    justify-content: center;
}

.paymentContentMain {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-around;
    padding: 70px;
}
.paymentData {
    font-weight: 700;
    font-size: 1.8rem;
    font-family: 'Baloo 2', sans-serif;
    margin-bottom: 10px;
}

.paymentDataTextP {
    font-weight: 600;
    margin-bottom: 10px;
}

.paymentContainerOne {
    display: flex;
    flex-direction: column;
    width: 60vw;
    justify-content: center;
}

.btnCardPayment {
    border-radius: 5px;
    margin: 0 10px 10px 0;
    border: 0.5px solid #000;
    background-color: #fff;
    text-align: center;
    padding: 5px 10px;
}

.btnCardPayment:hover {
    background-color: #63626246;
}

.btnCardPayment:focus {
    background-color: #63626246;
}

.buttonContent {
    display: flex;
    gap: 5px;
}

.imgCard{
    width: 50%;
}

@media (max-width: 740px) {
    .paymentContentMain {
        padding: 70px 20px;
    }
    .imgCard{
        width: 70%;
    }
  }

  @media (max-width: 500px) {

    .imgCard{
        width: 100%;
    }
    .paymentData {
        font-size: 1.3rem;
    }

  }