.screen-2-game-1{
    background-color: #AD4AFF;
    width: 100%;
    height: 100vh;
   
}

.screen-2-game-1 h1{
    font-family: 'Baloo 2';
    color: white;
    font-size: 2rem;
    font-weight:900;
    text-align: center;
    margin-top: -1.5rem;
}



.container-draw2{
    width: 28rem;
    height:25rem;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 3rem;
  
    margin-top: -1.9rem;
   
}

.container-draw2 svg{
    width: 28rem;
    height: 25rem;
    border-radius: 3rem;
    background-color: #ffffff;

    
}
.svg-game2 svg {
    fill: #ffffff;

  }
  .options-game2 {
    padding: 1em 0;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #ffffff;
    font-family: "Poppins", sans-serif;
    border-radius: 1rem;
    
  }
  .color-input-game2 label {
    margin-right: 0.5em;
  }
  #color-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    width: 40px;
    height: 40px;
    border: none;
    margin-left: 0.5rem;
  }
  #color-input::-webkit-color-swatch {
    border: 3px solid #000000;
    border-radius: 5px;
  }
  #color-input::-moz-color-swatch {
    border-radius: 50%;
    border: 3px solid #000000;
  }
  .color-options,
  .size-game2 {
    display: flex;
    align-items: center;
  }


  #size-input {
    margin-left: 0.3rem;
    border-radius: 50%; 
}

.back-game-draw{
    margin-left: 2rem;
    margin-top: 0.5rem;
}

.button-game1-draw1{
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

@media (max-width: 1230px) {
  .container-draw2 svg {
      width: 24rem;
      height: 20rem;
      border-radius: 3rem;
      background-color: #ffffff;
      margin-top: 2rem;
      margin-left: 3rem;
  }

  .options-game2 {
      flex-direction: row;
  }

  .color-options,
  .size-game2 {
      margin-bottom: 0;
      margin-right: 1rem;
  }


}

@media (max-width: 487px) {
.options-game2 {
     padding: 0.5em;
    text-align: center;
    max-width: 85%;
    position: relative;
    left: 3.2rem;

}

.size-game2 {
  text-align: center;
}

.container-draw2 svg{
margin-left: 3rem;
}

.screen-2-game-1 h1{
margin-top: 0.5rem;
}

}



@media (max-width: 430px) {
.options-game2  {
    padding: 0rem;
    text-align: center;
    max-width: 80%;
    position: relative;
    left: 3rem;

}
.container-draw2 svg{
  margin-left: 2.7rem;
}

.screen-2-game-1 h1{
  font-size: 1.5rem;
  }


}
@media (max-width: 410px) {
.back-game-draw {
    margin: 0rem;
    margin-top: 0.5rem;
    width: 2.5rem;

}
.options-game2 {
  padding: 1rem;
  text-align: center;
  margin-left: 0;
  max-width: 75%;
  position: relative;
  left: 4rem;
}
.container-draw2 svg{
 width: 70%;
 height: 60%;
 margin-left: 4rem;
}
}