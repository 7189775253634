.container-tutorial-three {
    background: url(../../../assets/bg-3-game-3.jpg) center no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
}
@media screen and (max-width:485px) {
    .container-tutorial-three {
        height: 115vh;
    }
}
@media screen and (max-width:450px) {
    .container-tutorial-three{
        height: 125vh;
    }
}