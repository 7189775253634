
.card-first{
    width: 22rem;
    height: 8.375rem;
    background-color: #F2F2F2;
    border-radius: 15px;
    font-family: 'Baloo 2';
    font-size: 18px;
    box-shadow: 2px 2px 2px 4px #00c3ffc8 ;
    display: flex;
    margin-top: 6rem;
    
}

.card-first img{
    width: 9rem;
    height: 10rem;
    
}

.card-first p{
    color: #000;
    font-weight: bold;
    margin-top: 2rem;
    margin-left: 1.5rem;
}

.card-first span{
    color: #00C2FF;
    font-weight: bold;
}







.cards-top-tutorials{
    height: 30vh;
    display: flex;
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    margin-left:1.875rem;
    
    
    
   
    
}

.cards-videos{
   margin-left: 4.78rem;
    margin-top: 3.125rem;
    max-width: 100%;
    flex-wrap: wrap;
  
}

 .cards-videos-1{
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: row;
    gap: 2.1875rem;
    margin-bottom: 3.125rem;
    flex-wrap: wrap;
   
}

.cards-videos-2{
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: row;
    gap: 2.1875rem;
    margin-top: 0.625rem;
    margin-bottom: 3.125rem;
    flex-wrap: wrap;
   
}

.cards-videos-3{
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: row;
    gap: 2.1875rem;
    flex-wrap: wrap;
   
   
}

.title-tutorials-responsible{
    display: flex;
    flex-direction: row;
    margin-left: 4.25rem;
    justify-items: center;
    margin-bottom: 1.875rem;
    gap: 0.625rem;
}



 .title-tutorials-responsible p{
    color: #000;
    font-family: 'Montserrat';
    font-weight: bold;
    margin-top: 0.625rem;
    font-size: 1.2rem;
   
 } 

.title-tutorials-responsible span{
    color: #F1B709;
}

.cards-top-container{
    display: flex;
    gap: 2rem;
    margin-top: 6rem;
}





@media (min-width: 990px) {
    .container-new-header {
        display: none; 
    }
}

@media (max-width: 990px) {
    .cards-top-tutorials{
        display: none; 
    }
    .title-tutorials-responsible{
        margin-top: 8rem;
    }
    .cards-videos{
        margin: 0rem; 
    }

    .cards-videos-1{
        margin: 0rem; 
    }
}

@media (max-width: 610px) {
    .cards-videos{
        margin: 0rem; 
    }

    .cards-videos-1{
        margin: 0rem; 
    }
    .title-tutorials-responsible p{
       margin-left: 0.4rem;
       
       
    }

    .title-tutorials-responsible{
        gap: 0rem;
    }
    .header-responsible-new{
        background-color: #00C2FF;
    }
    
}

@media (max-width: 1270px) {
      
    .card-first{
        display: none;
    }
    
}

@media (max-width: 1041px) {
      
    .cards-videos{
        padding-left: 0.5rem;
    }
    
}




