
  .cards-media {
    width: 17rem;
    height: 25rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid;
  }
  
  .cards-media img {
    width: 16.875rem;
    height: 17.5rem;
    border-radius: 0.625rem;
    margin-bottom: 0;
  }
  
  .cards-media h1 {
    font-size: 0.9rem;
    margin-top: 0.625rem;
    margin-bottom: 0.3125rem;
    max-width: 95%;
    text-align: center;
    margin-left: 0.25rem;
  }
  
  .cards-media p {
    font-size: 0.7rem;
    margin-top: 0;
    text-align: justify;
    max-width: 90%;
    position: relative;
    left: 0.3125rem;
  }
  
  @media (max-width: 800px) {
    .cards-media {
      margin: 0;
    }
  }
  
  @media (max-width: 430px) {
    .cards-media p {
      font-size: 0.6rem;
    }
    .cards-media {
      width: 17rem;
      height: 28.125rem;
      border-radius: 0.625rem;
      border: 0.0625rem solid;
    }
  }
  