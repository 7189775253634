.secondary-container-games{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}
.back-games{
    top: 1.8rem;
    left: 2rem;
    position: fixed;
}
.sound-games {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.sound-games img {
    width: 50px;
}
.sound-games p {
    color: #FFFF;
}
.button-games {
    width: 4rem;
}
.secondary-container-games h3 {
    font-weight: 800;
    font-size: 1.8rem;
}
.span-1{
    color: #40076F;
}
.span-2{
    color: #FFBF00;
}
.span-3{
    color: #FA00FF;
}

@media screen and (min-width:1120px) {
    .sound-games img {
        width: 60px;
    }
    .button-games {
        width: 5rem;
    }
    .secondary-container-games h3 {
        font-size: 2.3rem;
    }
}