@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,700&family=Poppins:wght@300&display=swap);

.modalCenter{
    position: relative;
    right: 9rem;
    width: 50rem;
    background-color: #FFEB68 !important;
    border-radius: 0.6rem !important;
    border: solid 2px #AD4AFF !important;
    margin-right: 20rem !important;
    right: 9rem !important;
}

.h4-title-modal{
    font-size: 2rem;
    font-weight: 800;
}

.h5-subtitle-modal{
    font-size: 1.5rem;
    font-weight: 600;
}



.modalCenter img{
    width: 5rem;
    height: 5rem;
    border-radius: 5rem !important;
}
.align-center-modal-img{
    display: flex;
    justify-content: center;
}
.align-center-modal-text{
    text-align: center;
}
.align-btn-modal{
    display: flex;
    justify-content: space-around;
    background-color: #FFEB68;
    margin-top: 5rem;
}
.container-modal{
    margin: 0 auto !important;
}

.button-modal-ai{
    background-color: #AD4AFF !important;
    color: white !important;
    border: none !important;
    width: 20rem!important;
    height: 3rem !important;
    height: 3rem !important;
    border-radius: 1rem !important;
    margin-top: 2rem !important;
    font-size: 1.2rem !important;
    font-family: 'Poppins', sans-serif !important;
    
}
.btnmodal-ai{
    background-color: #AD4AFF !important;
    color: white !important;
    font-weight: 700 !important;
    font-family: 'Poppins', sans-serif !important;
    font-size: 1rem !important;
    width: 15rem !important;
    border-radius: 1rem !important;
}
.button-modal:hover{
    background-color: #AD4AFF !important;
    color: white !important;
}

.button-modal:focus {
    outline: none;
}



@media (max-width: 600px) {
.button-modal-ai{
    background-color: #AD4AFF;
    color: white;
    border: none;
    width: 20rem;
    height: 3rem;
    border-radius: 3rem;
    margin-top: 2rem;
    font-size: 1.2rem;
    
}
.modalCenter{
    position: 0;
    right: 0rem;
    width: 100%;
    height: 37rem !important;
    background-color: #FFEB68;
    border-radius: 0.6rem !important;
    border: solid 2px #AD4AFF !important;
    right: 0rem !important;
}

.h4-title-modal{
    font-size: 2rem;
    font-weight: 800;
}

.h5-subtitle-modal{
    font-size: 1.5rem;
    font-weight: 600;
}



.modalCenter img{
    width: 5rem;
    height: 5rem;
    border-radius: 5rem !important;
}
.align-center-modal-img{
    display: flex;
    justify-content: center;
}
.align-center-modal-text{
    text-align: center;
}
.align-btn-modal{
    display: flex;
    justify-content: space-around;
    background-color: #FFEB68;
    margin-top: 5rem;
}
.container-modal{
    margin: 0 auto !important;
}

.btnmodal-ai{
    background-color: #AD4AFF !important;
    color: white !important;
    font-weight: 700 !important;
    font-family: 'Poppins', sans-serif !important;
    font-size: 0.8rem !important;
    width: 10rem !important;
    border-radius: 1rem !important;
}
.button-modal:hover{
    background-color: #AD4AFF !important;
    color: white !important;
}

.button-modal:focus {
    outline: none;
}}


@media (max-width: 400px) {
    .modalCenter{
        position: 0;
        right: 0rem;
        width: 100%;
        height: 45rem !important;
        background-color: #FFEB68;
        border-radius: 0.6rem !important;
        border: solid 2px #AD4AFF !important;
        right: 0rem !important;
    }
    .btnmodal-ai{
        background-color: #AD4AFF !important;
        color: white !important;
        font-weight: 700 !important;
        font-family: 'Poppins', sans-serif !important;
        font-size: 0.6rem !important;
        width: 8rem !important;
        border-radius: 1rem !important;
    }
    
}