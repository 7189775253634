#bg-login-c{
    overflow: hidden;
}
.bg-login{
    width: 100vw;
    height: 100vh;
    background-color: #f5f5f5;
}

.login-container{
    display: flex;
    justify-content:space-between;
    flex-direction: row;
}

.logo{
    display: flex;
    justify-content: end;
}

.subtitleL{
    margin-left: 0;
    font-size: 1.7rem;
    font-weight: 800;
}

.paragrafL{
    margin: 0.7rem 0;
}


.forms-rigth{
    display: flex;
    flex-direction: column;
    width: 70vh;
    margin: 7rem 6rem;
    padding: 0.2rem;
}

.forms-login{
    width: 70vh;
    padding: 0.2rem;
}

.login-paragraf{
    font-size: 0.8rem;
}

.btn-login{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.6rem;
}
.image-left{

height: 100vh;

}

.link-login{
    font-size: 0.9rem;
    margin-left: 0.2rem;
    font-weight: 600;
    color: #C9A5FF;
}

.link-login:hover{
    color: #AD4AFF;
}



@media (max-width:1070px) {
    .bg-login{
        position: none;
    }
    .img-left{
        display: none;
    }
 
}

@media (max-width:530px) {

    .info-login{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .forms-rigth{
        width: 70vh;
        margin: 3rem 2rem;
    }

    .forms-login{
        width: 100%;
    }

    .btn-login{
        justify-content: space-between;
    
    }

    .btn-login p, span{
        font-size: 0.8rem;
    }

    .link-login{
        font-size: 0.8rem;
    }
}

@media (max-width:480px){

    .btn-login{
        justify-content: space-between;
        flex-direction: column;
        gap: 1rem;
    }
}




