.header-responsible-new{
    background-color: #0ABBB5;
}

.header-brand, .header-navbar a {
    color: #000000 !important;
    font-family: Poppins;
    font-size: 1rem;
    font-weight: 500;
    gap: 13px;
    text-decoration: none;
}

.container-new-header1{
    background-color: #0ABBB5;
}

.header-brand img {
    width: 110px;    
}

.avatar-header{
    width: 3.5rem;
    height: 3.3rem;
    border-radius: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: right;
    margin-left: 1rem;  
}

.img-avatar-header{
    width: 3.9rem;
    height: 3.7rem;
    padding-left: 0.3rem;
    
}

@media (min-width: 990px) {
    .header-navbar {
        display: none; 
    }
}