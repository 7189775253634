.fun-balls {
    color: #F2B705;
}
.container-cover-two {
    background: url(../../../assets/bg-1-game-2.jpg) center no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
}
.container-cover-two h3 {
    color: #FFFF;
}

