@import url(https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700;800&family=Montserrat:wght@300;400;700;800&family=Poppins:wght@300;400;600;700&display=swap);
*{
    font-family: 'Baloo 2', sans-serif;
}

 .welcomeHome{
    background: url(../../assets/fundoRoxo.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center left;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 8.6rem;
    padding: 2rem 0;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.navbar {
    display: block;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}

.welcomeContainerOne {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.homeWelcomeParagraph{
    font-size: 1.9rem;
    color: #F2B705;
    font-family: 'Baloo 2', sans-serif;
    font-weight: 600;
}

.homeWelcomeText{
    font-size: 3rem;
    color: #FFFFFF;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    
}

.homeWelcomeText span {
    color: #F2B705;
}

.homeWelcomeImg{
    width: 575px;
    height: 350px;
}


.homeWelcomeButton{
    background-color: #F2B705;
    border-radius: 20px;
    border: 0;
    width: 300px;
    height: 50px;
    margin-top: 15px;
    font-size: 1.2rem;
    font-weight: 600;
}

.homeWelcomeButton:hover {
    background-color: rgba(255, 204, 51, 0.897);
    transition: 1.3s;
}

.homeWelcomeButton a {
    text-decoration: none;
    color: #FFFFFF;
}

.homeWelcomeButton a:hover {
    transition: 1.3s;
    color: #050505;
}

.mainHome{
    background: url(../../assets/Bg-session-kids.png) center center no-repeat;
    background-size: cover;
}

.cardsHomeTasks {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 20px;
    margin-bottom: 70px;

}

.tasksCard {
    margin-top: 50px;
    width: 370px;
    height: 260px;
}

.tasksCard h3 {
    color: #FFFFFF;
    font-family: 'Baloo 2', sans-serif;
    font-weight: 700;
    font-size: 1.2rem;
}

.home-avatar-male{
    width:  200px;
}
.home-avatar-female{
    width: 300px;
}

.watchYoutube{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: 3rem 0;
}

.watchYoutubeCard {
    border-radius: 40px;
    transform: translateX(40px);
    border: 2px solid #000000;
}

.AIscpace {
    background-color: #AD4AFF;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center left;
    width: 100%;
    height: 100vh;   
    gap: 12rem;
    display: flex;
    justify-content: center;
    align-items: center;

}

.identifyImg {
    width: 500px;
    height: 400px;
}

.AIscpaceText h3 {
    font-size: 2.9rem;
    color: #FFFFFF;
    font-weight: 700;
    font-family: 'Baloo 2', sans-serif;
    margin-bottom: 20px;
}

.AIscpaceText ul li {
    color: #FFFFFF;
    font-size: 1.3rem;
    margin-bottom: 15px;
}

.buttonIA {
    background-color: #75E5BD;
    border: none;
    width: 303.48px;
    height: 68.96px;
    border-radius: 20px;
    margin-top: 20px;
}

.buttonIA:hover {
    background-color: #75e5bebd;
    
}

.buttonIA a {
    text-decoration: none;
    color: #FFFFFF;
    font-family: 'Baloo 2', sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
}

.plans {
    background-color: #F5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 50px;
 
}

.plans h2 {
    color: #000000;
    font-size: 3rem;
    font-family: 'Baloo 2', sans-serif;
    font-weight: 700;
    margin-top: 30px;
}

.plansCards{
    display: flex;
    justify-content: center;
    gap: 50px;
    flex-wrap: wrap;
}

.plansCardContent {
    width: 19rem;
    border: solid 1px #AD4AFF;
    border-radius: 30px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(5px 2px 6px #AD4AFF);
    box-shadow: 1px 2px 2px 2px inset #e2bfff;
    margin-top: 20px;
}

.plansValue {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #FFBF00;
    border: 2px solid #fff;
    background-color: #fff;
    box-shadow: 0 7px 7px rgba(154, 16, 196, 0.712);
    border-radius: 5px;
    width: 240.56px;
    height: 118.38px;
    transform: translateY(-45px);
    text-align: center;
}
.plansValueMoney {
    font-size: 2rem;
    color: #FFBF00;
}

.plansValueMoney span {
    font-size: 16px;
}

.plansValue {
    font-size: 1.5rem;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
}

.plansContent {
    transform: translateY(-35px);
}

.plansContentOption {
    display: flex;
    gap: 10px;
    margin: 18px 0;
}

.plansContentOption p {
    color: #000;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}

.plansCardContent button {
    background-color: #F2B705;
    border: none;
    width: 166.97px;
    height: 42.31px;
    border-radius: 10px;
    text-align: center;
    transform: translateY(-35px);
}

.plansCardContent button:hover {
    background-color: rgba(255, 204, 51, 0.897);
    transition: 1.3s;
}

.plansCardContent button a {
    text-decoration: none;
    color: #FFFFFF;
    font-weight: 600;
}

.plansCardContent button a:hover {
    transition: 1.3s;
    color: #000;
}

.socialMedia {
    background-color: #AD4AFF;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap-reverse;
}

.socialMediaContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;

}

.socialMediaContent h3 {
    color: #FFFFFF;
    font-size: 2rem;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}

.socialMediaContent h3 span {
    color: #FFBF00;
}

.socialMediaIcons a {
    margin-left: 20px;
}

.barFooter {
   width: 100%;
}

.feedback {
    background-color: #F5f5f5;
    padding-top: 20px;
}

.feedback h2{
    color: #000;
    font-size: 3rem;
    font-family: 'Baloo 2', sans-serif;
    font-weight: 700;
    text-align: center;
}



@media (max-width: 1280px) {

    .homeWelcomeText {
        font-size: 2rem;
    }

    .homeWelcomeImg{
        width: 300px;
        height: 265px;
    }

    .AIscpaceText h3 {
        font-size: 2rem;
        margin-top: 50px;
    }
    
    .AIscpaceText ul li {
        font-size: 1rem;
    }

}

@media (max-width: 1080px) {
    .watchYoutube img {
        width: 200px;
        height: 300px;
    }
    .watchYoutubeCard {
        height: 200px;
        width: 350px;
        margin-right: 20px;
    }

    .welcomeHome {
        margin-top: 100px;
    }

    .AIscpace {
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    .AIscpace img {
        width: 400px;
        height: 300px;
    }

    .buttonIA {
        width: 203.48px;
        height: 68.96px;
    }
}

@media (max-width: 960px) {
    .homeWelcomeText {
        font-size: 2rem;
    }

    .homeWelcomeParagraph{
        font-size: 1.8rem;
    }

    .homeWelcomeImg{
        width: 300px;
        height: 265px;
    }

    .homeWelcomeButton{
        width: 200px;
        height: 50px;
        font-size: 1rem;
    }

    .socialMediaContent h3 {
        margin-top: 40px;
    }

    .AIscpace{
        gap: 20px;
    }
    
    .welcomeHome{
        gap: 20px;
    }

    .AIscpace img {
        height: 300px;
    }

    .buttonIA {
        width: 153.48px;
        height: 48.96px;
    }

    .buttonIA a{
        font-size: 1rem;
    }
}

@media (max-width: 720px) {

    .cardsHomeTasks {
        gap: 0;
        padding: 20px;
        margin-bottom: 70px;
        margin-left: 30px;
    }
    
    .tasksCard {
        margin-top: 50px;
        width: 370px;
        height: 260px;
    }

    .homeWelcomeImg{
        width: 200px;
        height: 245px;
    }

    .carousel {
        display: none;
    }    

    .watchYoutube img {
        display: none;
    }
    
    .watchYoutube img {
        width: 150px;
        height: 200px;
    }
    .watchYoutubeCard {
        height: 300px;
        width: 500px;
        margin-right: 100px;
    }

    .AIscpace{
        gap: 20px;
    }

    .AIscpaceText h3{
        font-size: 1.5rem;
        margin-bottom: 20px;
    }

    .AIscpaceText ul li{
        font-size: 0.9rem;
    }

    .AIscpace img {
        margin-bottom: 100px;
    }

    .tasksCard img {
        width: 330px;
        height: 220px;
    }

}

@media (max-width: 605px) {
    .watchYoutube img {
        display: none;
    }

    .watchYoutubeCard {
        transform: translateX(0);
    }

    .watchYoutubeCard {
        height: 300px;
        width: 400px;
        margin-right: 0;
    }

    .AIscpace{
        gap: 20px;
    }

    .avatarSocialMedia{
        display: none;
    }

    .socialMediaContent{
        padding: 3rem 1rem;
    }

    .socialMediaContent h3 {
        font-size: 1.5rem;
    }    

    .plans h2 {
        font-size: 2rem;
    }

    .socialMediaIcons a img {
        width: 40px;
    }

    .avatarSocialMedia {
        width: 80%;
    }

    
}

@media (max-width: 405px) {
    .homeWelcomeText {
        font-size: 1.8rem;
    }

    .homeWelcomeParagraph{
        font-size: 1.8rem;
    }

    .homeWelcomeImg{
        width: 200px;
        height: 245px;
    }

    .homeWelcomeButton{
        width: 200px;
        height: 50px;
        font-size: 1rem;
    }

    .watchYoutubeCard {
        height: 300px;
        width: 340px;
        margin-right: 0;
    }

    .cardsHomeTasks {
        margin-left: 10px;
    } 

    .identifyImg {
        display: none;
     }

     .plansValueMoney {
        font-size: 1.5rem;
        color: #FFBF00;
    }
    
    .plansValueMoney span {
        font-size: 14px;
    }
    
    .plansValue {
        font-size: 1.3rem;
        font-weight: 700;
        font-family: 'Poppins', sans-serif;
    }
    .plans h2{
        text-align: center;
    }

    .AIscpaceText{
        margin-left: 1rem;
    }
}
