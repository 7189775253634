.button-steps button {
    background-color: #F2B705;
    color: #000;
    padding: 14px 16px;
    border: none;
    border-radius: 10px;
    font-family: Poppins;
    font-size: 0.8rem;
    font-weight: 500;
}
.button-steps button:hover {
    background: #ffbf00c0;
    color: #f2f2f2;
    transition: 0.3s;
}

@media screen and (max-width: 768px) {
    .button button {
        margin-left: 40px;
        font-size: 0.6rem; 
    }
}