.finishGameThree {
    background: url(../../../assets/bg-game-3-finish.jpg) center center no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    gap: 30px;   
    text-align: center;
}
.titleFinishGameThree {
    font-size: 3rem;
    color: #fff;
    font-family: 'Baloo 2', sans-serif;
    font-weight: 700;
}
.finishGameThree p {
    font-size: 1.2rem;
}
.Button-game-three {
    border: none;
    color: #1E1E1E;
    background-color: #31CEFF;
    border: 1px solid #00C2FF;
    border-radius: 10px;
    width: 186.23px;
    height: 46.92px;
    font-weight: bold;
}
.Button-game-three:hover {
    background-color: #5dd3f7;
}