.edit-btn{
    display: flex;
    background-color: #F74354;
    border: none;
    text-transform: uppercase;
    color: #FFFFFF;
    border-radius: 10px;
    padding: 0.6rem;
    font: 600 normal 1em 'Poppins';}

.p-edit{
    padding-left: 0.2em;
    padding-right: 0.2em;
}

.img-edit{
    position: relative;
    top: 0.1rem;
    left:0.3rem;
}

@media (max-width:530px){

    .edit-btn{
        border-radius: 10px;
        padding: 0.4rem;
        font: 600 normal 'Poppins';}
 
        
        .p-edit{
            padding-left: 0.2em;
            padding-right: 0.2em;
            font-size: 0.9em ;
          }
        
        .img-edit{
            width: 1.2em;
            position: relative;
            top: 0rem;
            left:0.3rem;
        }
}
