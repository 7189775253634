.container-cover-three {
    background: url(../../../assets/bg-1-game-3.jpg) center no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
}
.container-cover-three h3 {
    color: #FA00FF;
}