.finishGameTwo {
    background: url(../../../../assets/gameTwoFinish.png) center center no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    gap: 30px;   
    text-align: center;
}
.titleFinishGameTwo {
    font-size: 3rem;
    color: #fff;
    font-family: 'Baloo 2', sans-serif;
    font-weight: 700;
}
.finishGameTwo p {
    font-size: 1.2rem;
}