

.container-family-media{
    margin-left: 8rem;
}

.text-medias {
    text-align: justify;
}

.text-medias h1 {
    font-weight: bold;
    margin-top: 4.55rem;
    margin-bottom: 2rem;
    font-size: 1.4rem;
    margin-left: 1rem;
}

.text-medias p {
    font-size: 0.9rem;
    margin-bottom: 1rem;
    max-width: 56rem ;
}

.text-medias iframe {
    width: 60%; 
    height: 24rem;
    border-radius: 2rem;
}

.first-row-media {
    display: flex;
    flex-direction: row;
    gap: 3rem; 
    margin-left: 2rem; 
    flex-wrap: wrap;
}

.second-row-media {
    display: flex;
    flex-direction: row;
    gap: 3rem; 
    margin-left: 2rem; 
    margin-top: 4rem; 
    margin-bottom: 3.125rem; 
    flex-wrap: wrap;
}

.poadcasts {
    display: flex;
    flex-direction: row;
    gap: 3rem; 
    margin-left: 2rem; 
    margin-top: 2rem; 
    margin-bottom: 3rem;
    flex-wrap: wrap;
}

.title-poadcast {
    font-weight: bold;
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 1.4rem;
    margin-left: 1rem;
}

.footer-medias{
    max-width: 95%;
    margin-left: 5.7rem;
}

@media (max-width: 560px) {
      
    .card-media-container{
        margin-left: -2rem;
    }
    .poadcasts{
        margin-left: 0rem;
    }
    .first-row-media{
        margin-left: 0rem;
    }
    .second-row-media{
        margin-left: 0rem;
    }
    .text-medias{
        margin-left: -2rem;
    }
   
}

@media (max-width: 445px) {
      
    .text-medias{
        margin-left: -3rem;
        max-width: 90%;
    }
   
}

@media (max-width: 410px) {
      
    .card-media-container{
        margin-left: -4rem;
    }
    .poadcasts{
        margin-left: 0rem;
    }
    .first-row-media{
        margin-left: 0rem;
    }
    .second-row-media{
        margin-left: 0rem;
    }
    .text-medias{
        margin-left: -2rem;
    }
}

@media (max-width: 990px) {
      
    .text-medias{
        max-width: 80%;
        margin-top: 7rem;
        text-align: center;
        
    }
    .text-medias h1{
       margin-bottom: 1rem;
    }
    .footer-medias{
        max-width: 100%;
        margin-left: 0rem;
    }
}

@media (max-width: 988px) {
      
    .iframe-playlist iframe{
        width: 25rem;
        
    }
}

@media (max-width: 656px) {
      
    .iframe-playlist iframe{
        width: 20rem;
        
    }
}

@media (max-width: 478px) {
      
    .iframe-playlist iframe{
        width: 18rem;
        
    }
}

@media (max-width: 426px) {
      
    .iframe-playlist iframe{
        width: 15rem;
        
    }
}


