.title-tutorials-lavagem{
    display: flex;
    flex-direction: row;
    margin-left: 8rem;
    justify-items: center;
    gap: 10px;
    margin-bottom: 30px;
    margin-top: 4.55rem;
}

.title-tutorials-lavagem p{
    color: #000;
    font-family: 'Montserrat';
    font-weight: bold;
    margin-top: 10px;
    font-size: 1.2rem;
}

.video-step-by-step{
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.video-step-by-step iframe{
    border-radius: 40px;
}

.text-step-by-step{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.title-step-by-step{
    font-family: 'Montserrat';
    font-weight: bold;
    margin-top: 60px;
    font-size: 1.3rem;
}

.text-top-step-by-step{
    font-family: 'Montserrat';
    display: flex;
    justify-content: center;
    flex-direction: row;  
}

.img-step-by-step{
    display: flex;
    justify-content: center;
    flex-direction: row;  
    margin-top: 30px;
    margin-left: 50px;
    
}

.products-step-by-step{
    display: flex;
    justify-content: center;
    flex-direction: row;
    font-weight: 300;
    font-size: 0.8rem;
    font-family: 'Montserrat';
   
}

.products-step-by-step span{
    margin-left: 3.8rem;
}

.recomend-step-by-step{
    margin-top: 7px;
    font-weight: 300;
    font-size: 0.8rem;
    font-family: 'Montserrat';
}

.content-steps{
    text-align: justify;
    max-width: 60%;
}

.title-steps{
    font-family: 'Montserrat';
    margin-top: 50px;
    font-size: 1.2rem;
    text-align: left;
    font-weight: 500;
    
}

.title-steps span{
    color: #F2B705;
    font-weight: bold;
}

.explanation-step-by-step{
    font-family: 'Baloo 2';
    font-size: 0.9rem;
    font-weight: 400;
    text-align: justify;
    margin-top: 16px;
}

.button-steps{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1.5rem;
    gap: 28px;
    margin-bottom: 2rem;
    text-decoration: none;
    
}



.notes-img-step{
    margin-top: 2.3rem;
}

.step-by-step{
    margin: 0;
    padding: 0;
}

.step-left{
    margin: 0;
    padding: 0;
}

.footer-steps{
    max-width: 95%;
    margin-left: 5.7rem;
}


@media (max-width: 560px) {
    .explanation-step-by-step{
        font-size: 1rem;
    }
   .content-steps{
    max-width: 75%;
   }
} 

@media (max-width: 660px) {
    .video-step-by-step iframe {
        width: 34rem; 
        height: 20rem; 
      }
} 

@media (max-width: 580px) {
    .video-step-by-step iframe {
        width: 30rem; 
        height: 20rem; 
      }
} 

@media (max-width: 490px) {
    .video-step-by-step iframe {
        width: 26rem; 
        height: 20rem; 
      }
} 

@media (max-width: 420px) {
    .video-step-by-step iframe {
        width: 22rem; 
        height: 20rem; 
      }
      .content-steps{
        text-align: left;
      }
}

@media (max-width: 990px) {
    .container-step-by-step{
        margin-top: 7rem;
    }
    .footer-steps{
        max-width: 100%;
        margin-left: 0rem;
    }
}

@media (max-width: 880px) {
    .title-tutorials-lavagem{
        margin-left: 1rem;
    }
}
