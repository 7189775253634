.bg-register {
    background-image: url(../../assets/Fundo.jpg);
    background-color: #f5f5f5;
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    height: 100vh;
    align-items: center;
}


.subtitle{
    font-size: 1.7rem;
    font-weight: 800;
}

.container-register {
    width: 70%;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    position: relative;
    top: 3rem;
    padding: 2rem;
    margin: auto;
}

.forms-center {
    width: 60%;
    padding: 2em;
}


.info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 2rem;
    text-align: center;
}

@media (max-width:530px) {
    .bg-register {
        background-image: none;
    }

    .title-register{
        font-size: 1.2rem;
    }

    .container-register{
        width: 100%
    }

    .forms-center {
        width: 100%;
    }
}