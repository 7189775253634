.navbar-header {
    background-color: #FFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    color: white;
}
.brand,.navbar a{
    color: #000000;
    font-family: Poppins;
    font-size: 1rem;
    font-weight: 500;
    gap: 13px;
    text-decoration: none;
}
.container-buttons-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 15px;
}
.container-buttons-header #button-login {
    background: transparent;
    color: #F2B705;
    padding: 14px 25px;
    border: none;
    border: 1px solid #F2B705;
    border-radius: 10px;
    font-family: Poppins;
    font-size: 0.8rem;
    font-weight: 500;
}
.container-buttons-header #button-login:hover {
    background-color: #F2B705;
    color: #fff;
    transition: 1.3s;
}
.brand img {
    width: 110px;    
}

* {
    text-decoration: none;
    padding: 0;
    margin: 0;
  }
  
  .header {
    
    display: flex;
    justify-content: space-around;
  }
  
  .logo {
    margin-left: 25px;
  }
  
  .menu {
    margin-right: 75px;
  }
  
  .menu li {
    padding-left: 25px;
  }
  
  .footer {
    width: 100%;
    background-color: rgb(0, 0, 0, .7);
    position: relative;
  }
  
  .iconsFooter a {
    margin-right: 35px;
    color: aliceblue;
    height: 5px;
  }
  
  .main-cadastro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 70px ;
    align-items: center;
  }

  .form-row > div, .formCadastro > div {
    margin-top: 3% !important;
  }
  
.btn2 {
  font-family: 'Caudex', serif;
  font-size: 1.2rem;
  width: 180px;
  height: 40px;
  border-radius: 20px;
  border: none;
  color: #ffffff;
  background-color: #536DFE;
  margin: 5% 5% 0 0 ;
}

.btn2:hover {
  color: #ffffff;
  transform: scale(1.1);
  transition: all .3s ease-in-out;
}

#inputRG{
  width: 200px;
}

#inputCEP {
  width: 120px;
}

.buscaCep {
  display: flex;
}

.botaoBusca {
  font-family: 'Caudex', serif;
  font-size: 1.2rem;
  width: 110px;
  height: 40px;
  border-radius: 0 20px 20px 0;
  border: 2px solid rgb(83, 109, 254);
  color: #536DFE;
  background-color: rgb(255, 255, 255);
}

.botaoBusca:hover {
  background-color: #536DFE;
  color: #ffffff;
}

.btn {
  font-family: 'Caudex', serif;
  font-size: 1.2rem;
  width: 120px;
  height: 44px;
  border-radius: 20px;
  border: 2px solid rgb(83, 109, 254);
  color: #536DFE;
  background-color: rgb(255, 255, 255);
}

#inputCEP {
  border-radius: 5px 0 0 5px;
}

.msgDesativada {
  display: none;
}

.verificacaoCepMsg {
  color: red;
}

.inputCEPErro {
  box-shadow: inset 0 0 7px red;
}

.inputCEPSucesso {
  box-shadow: inset 0 0 7px green;
}

/* darkmode */
[data-theme="dark"] {
  background-color: #f9f2f2 !important;

}
[data-theme="dark"] .navbar{
  background-color: #696464 !important;

}
[data-theme="dark"] a{

  color: #f1d814 !important;
}
[data-theme="dark"] .btn-primary{
 background-color: #eee;

}

.form-check {
margin-top: 9px;
}
[data-theme="dark"] .dropdowm-item {
  background-color: #222 !important;
color: #222 !important ;}