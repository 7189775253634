.text-notes {
    margin-left: 8rem; /* Changed from 8rem to 2rem */
    text-align: justify;
}

.text-notes h1 {
    font-weight: bold;
    margin-top: 4.55rem; 
    margin-bottom: 0.5rem; 
    font-size: 1.4rem;
    margin-left: 1.875rem; 
}

.text-notes p {
    font-size: 0.9rem;

    max-width: 50%; 

    max-width: 50%; 
}

.container-notes {
    margin-left: 8rem; 
    margin-top: 2.5rem; 
}

.container-notes2 {
    display: flex;
    flex-wrap: wrap;
    gap: 1.875rem; 
}

.addNote-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 16.25rem; 
    border: 1px solid rgb(173, 74, 225, 100%);
    height: 11.25rem; 
    cursor: pointer;
    border-radius: 10px; 
    color: #000;
}

.icon-plus {
    padding: 0 0.875rem; 
    border: 2px solid; 
    width: fit-content;

    border-radius: 55%;
    border-radius: 50%;

    font-size: x-large; 
    color: #AD4AFF;
    margin-bottom: 0.5625rem; 
}

.content {
    display: contents;
}

textarea {
    padding: 0.625rem; 
    padding-top: 0.875rem; 
    outline: none;
    resize: none;
    border: none;
    background: transparent;
    font-size: 0.9375rem; 
    width: 16.25rem; 
    height: 11.25rem; 
    font-family: 'Montserrat';
    text-align: left;
}

textarea::-webkit-scrollbar {
    width: 0.25rem; 
}

textarea::-webkit-scrollbar-thumb {
    border-radius: 0.625rem; 
    background-color: #ffffff;
}

.item-notes {
    position: relative;
    width: 16.25rem; 
    height: 11.25rem; 
    border-radius: 0.3125rem; 
}

.remove-notes {
    position: absolute;
    right: 0.375rem; 
    cursor: pointer;
    color: white;
    font-size: 1.25rem; 
    font-family: 'Montserrat';
}

@media (max-width: 990px) {
    .text-notes   {
        margin-left: 0rem;
        padding: 0rem;
        margin-top: 8rem;
        text-align: center;
      }
      .text-notes p  {
        margin-left: 1rem;
        padding: 0rem;
        text-align: center;
        max-width: 90%;
      }
    .container-notes{
        text-align: center;
        margin-left: 5rem;
    }
   
}