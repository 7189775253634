* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.imgBack{
  width: 3rem;
  height: 3rem;
  margin: 1rem;
  position: absolute;
}
.align-row label{
  font-family: 'Poppins', sans-serif;
  margin-left: 0.4rem;
  font-size: 1rem;
}

.img-voltar{
  margin-left: 2rem;
}

.background-form{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}

.align-row{
  display: flex;
  flex-direction: row;
}

.titulo-form{
  text-align: center;
  font-size: 1.5rem;
  color: black;
  font-family: 'Montserrat', sans-serif;
  
}

.subtitulo-form{
  
  text-align: center;
  font-size: 1rem;
  color: #AD4AFF;
  margin-top: 2rem;
  
}
.alinhaCentro{
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 600px) {
  
.background-form{
  flex-direction: column;
  display: flex;
  justify-content: center;
  margin-right: 2rem;
}
.align-row label{
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;

}

.titulo-form{
  text-align: center;
  font-size: 1.5rem;
  color: black;
  
}
.align-row{
  display: flex;
  flex-direction: column;
}

.subtitulo-form{
  
  text-align: center;
  font-size: 1rem;
  color: #AD4AFF;
  font-family: 'Poppins', sans-serif;
  
}
.alinhaCentro{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
} }
