* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    background: #f2f2f2;
  }
  
  @keyframes slide {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  
  .logos {
    overflow: hidden;
    padding: 20px 0;
    background: #f5f5f5;
    white-space: nowrap;
    position: relative;
    width: 100%;
    height: 13rem;
    display: flex;
    align-items: center;
  }
  
  .logos:before,
  .logos:after {
    position: absolute;
    top: 0;
    width: 350px;
    height: 100%;
    content: "";
    z-index: 2;
  }
  
  .logos:before {
    left: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), #f5f5f5);
  }
  
  .logos:after {
    right: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), #f5f5f5);
  }
  
  .logos:hover .logos-slide {
    animation-play-state: paused;
  }
  
  .logos-slide {
    display: inline-block;
    animation: 35s slide infinite linear;
  }
  
  .logos-slide img {
    height: 100px;
    width: auto;
    margin: 0 40px;
  }