.edit-child-characters{
    width: 150px;
    height: 150px;
    cursor: pointer;
}
.edit-child-lock{
    width: 130px;
    height: 130px;
    margin: 10px;
    cursor: pointer;
}

@media (max-width:868px) {

    .img-select{
        width: 150px;
    }

    .modal-edit-child{
        width: 200px;
        justify-content: end;
    }
}